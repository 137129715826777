import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { DateTime } from 'luxon';
import { useParams } from 'react-router';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { PerformanceProvider, PerformanceCharts } from '@sunrun/recent-performance-charts';
import { useHybridAuthToken } from '../components/hybridAuthToken';
import useEnvConfig from '../hooks/useEnvConfig';
import { BATTERY_INFORMATION_CARD_FRAGMENT } from './BatteryInformationCard';
import { unwrapGraphQlList } from '../utils/unwrapGraphQlList';

import SystemStatus from './SystemStatus';
import TargetZone from './TargetZone';

const SYSTEM_PERFORMANCE_QUERY = gql`
  query SystemPerformanceQuery($prospectId: String) {
    prospect(prospectId: $prospectId) {
      id
      timeZone
      brightBox
      PTO
      isVivintSolarPostPTO
      ...BatteryInformationCardFields
      meters {
        edges {
          node {
            vendorName
            types
          }
        }
      }
    }
    site(prospectId: $prospectId) {
      id
      m2Date
    }
  }
  ${BATTERY_INFORMATION_CARD_FRAGMENT}
`;

const useStyles = makeStyles((theme) => ({
  performanceRoot: {
    padding: theme.spacing(2, 1, 1, 1),
  },
}));

const computeMinDateISO = (ptoDateISO, m2DateISO, zone) => {
  const ptoDateTime = DateTime.fromISO(ptoDateISO, { zone });
  if (ptoDateTime.isValid) {
    return ptoDateTime.toISO();
  }
  const m2DateTime = DateTime.fromISO(m2DateISO, { zone });
  if (m2DateTime.isValid) {
    return m2DateTime.toISO();
  }
  return DateTime.local().setZone(zone).startOf('day').minus({ year: 1 }).toISO();
};

const SystemPerformance = () => {
  const { prospectId } = useParams();
  const { performanceChartsBaseURL } = useEnvConfig();
  const hybridAuthToken = useHybridAuthToken();
  const { data, loading } = useQuery(SYSTEM_PERFORMANCE_QUERY, { variables: { prospectId }, returnPartialData: true });
  const classes = useStyles();

  if (loading || !hybridAuthToken) return <LinearProgress />;

  const { timeZone, PTO } = data?.prospect || {};
  const m2Date = data?.site?.m2Date;
  const isVivintSolarPostPTO = data?.prospect?.isVivintSolarPostPTO;
  const batteries = unwrapGraphQlList(data, 'prospect.batteries.edges');
  const hasBattery = Boolean(batteries.length);

  return (
    <>
      <SystemStatus prospectId={prospectId} hybridAuthToken={hybridAuthToken} />
      <PerformanceProvider>
        <TargetZone />
        <Card className={classes.performanceRoot}>
          <PerformanceCharts
            authToken={hybridAuthToken}
            baseUrl={performanceChartsBaseURL}
            internalUsage
            isOrangeCustomer={isVivintSolarPostPTO}
            maxDateISO={DateTime.local().setZone(timeZone).startOf('day').toISO()}
            minDateISO={computeMinDateISO(PTO, m2Date, timeZone)}
            prospectId={prospectId}
            timeZone={timeZone}
            withBatteryChart
            hasBattery={hasBattery}
          />
        </Card>
      </PerformanceProvider>
    </>
  );
};

export { computeMinDateISO };
export default SystemPerformance;
