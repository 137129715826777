export const getExperiencePaymentsUIUrl = (env) => {
  switch (env) {
    case 'prd':
      return `https://payments-ui.sunrun.com`;
    case 'majstg':
      return `https://majstg-payments-ui.sunrun.com`;
    case 'devmaj':
    case 'local':
    default:
      return `https://devmaj-payments-ui.sunrundev.com`;
  }
};

export const MessageType = Object.freeze({
  // Initialize autopay enrollment with given data
  INBOUND_INITIALIZE_AUTOPAY: 'SUNRUN_PAYMENTS_UI_INBOUND_INITIALIZE_AUTOPAY',
  // Initialize the payment ui for a payment with given data
  INBOUND_INITIALIZE_PAYMENT: 'SUNRUN_PAYMENTS_UI_INBOUND_INITIALIZE_PAYMENT',
  // Instructs the ui to go back to the previous step in the payment flow
  INBOUND_GO_BACK: 'SUNRUN_PAYMENTS_UI_INBOUND_GO_BACK',
  // Signals that autopay enrollment was successful
  OUTBOUND_AUTOPAY_SUCCESS: 'SUNRUN_PAYMENTS_UI_OUTBOUND_AUTOPAY_SUCCESS',
  // Signals that a payment was successful
  OUTBOUND_PAYMENT_SUCCESS: 'SUNRUN_PAYMENTS_UI_OUTBOUND_PAYMENT_SUCCESS',
  // Signals that the customer has reached the review payment screen
  OUTBOUND_REVIEW_SCREEN_OPEN: 'SUNRUN_PAYMENTS_UI_OUTBOUND_REVIEW_SCREEN_OPEN',
  // Analytics events that should be handled by the parent app
  OUTBOUND_ANALYTICS_EVENT: 'SUNRUN_PAYMENTS_UI_OUTBOUND_ANALYTICS_EVENT',
  // Cybersource session has expired
  OUTBOUND_SESSION_EXPIRED: 'SUNRUN_PAYMENTS_UI_OUTBOUND_SESSION_EXPIRED',
  // Signals that the payment ui has updated the title of the page
  OUTBOUND_UPDATE_MODAL_TITLE: 'SUNRUN_PAYMENTS_UI_OUTBOUND_UPDATE_TITLE',
  // Signals that control should be returned to the parent app
  OUTBOUND_EXIT_PAYMENT_UI: 'SUNRUN_PAYMENTS_UI_OUTBOUND_EXIT_PAYMENT_UI',
  // Log data that can be handled in the parent app as applicable
  OUTBOUND_LOG: 'SUNRUN_PAYMENTS_UI_OUTBOUND_LOG',
  // Signals that the webview or iframe containing the payment ui has been resized
  OUTBOUND_WINDOW_RESIZE: 'SUNRUN_PAYMENTS_UI_OUTBOUND_WINDOW_RESIZE',
});

export const PaymentFormLabels = Object.freeze({
  PAYMENT_AMOUNT: 'Payment amount',
  PAYMENT_METHOD: 'Payment method',
  ENTER_PAYMENT_DETAILS: 'Enter payment details',
  EDIT: 'Edit',
});

export const ResetPaymentWarningLabels = Object.freeze({
  CANCEL: 'Cancel',
  CHANGE_ANYWAY: 'Change anyway',
  RESET_PAYMENT_WARNING_TITLE: 'Information entered on this screen will be lost',
  RESET_PAYMENT_WARNING_TEXT: 'Changing the payment amount or method will clear any details you have entered here.',
});

export const PaymentAmountRadioOptions = Object.freeze({
  FULL_ACCOUNT_BALANCE: 'Full account balance',
  OTHER_AMOUNT: 'Other amount',
});

export const OtherPaymentAmountError = Object.freeze({
  MINIMUM_REQUIRED: 'Payments must be at least $1.00',
  MAXIMUM_EXCEEDED: 'Payments cannot exceed $100,000',
  EXCEEDED_INVOICE_TOTAL: 'Payments cannot exceed the invoice total',
});

// method chosen by the user in the parent app
// https://github.com/SunRun/experience-payments-ui/blob/develop/src/types/external-message-types.ts
export const PayMethodEnum = Object.freeze({
  CARD: 'card',
  CHECKING: 'echeck',
  SAVINGS: 'savings',
});

// maps the values offered to our users in the UI to what cybersource expects
// cybersource expects 'card' or 'echeck'
// checking and savings are grouped into 'echeck' with 'echeck_account_type'
export const cybersourcePaymentMethodEnum = {
  [PayMethodEnum.CARD]: 'card',
  [PayMethodEnum.CHECKING]: 'echeck',
  [PayMethodEnum.SAVINGS]: 'echeck',
};

export const eCheckAccountType = {
  [PayMethodEnum.CHECKING]: 'C',
  [PayMethodEnum.SAVINGS]: 'S',
};

// short label displaying to our customers / support reps
export const UIPaymentMethodEnum = {
  [PayMethodEnum.CARD]: 'Credit card',
  [PayMethodEnum.CHECKING]: 'Checking',
  [PayMethodEnum.SAVINGS]: 'Savings',
};

// long label displaying to our customers / support reps
export const UIPaymentMethodLongEnum = {
  [PayMethodEnum.CARD]: 'Credit card',
  [PayMethodEnum.CHECKING]: 'Checking Account',
  [PayMethodEnum.SAVINGS]: 'Savings Account',
};

export const sessionExpiredContent = Object.freeze({
  TITLE: 'Session Expired',
  DESCRIPTION: 'Your session expired due to a 15 minute time limit.',
  CTA: 'Please reload to try again.',
  CTA_BUTTON: 'Reload',
});

// type required for experience-payments-ui
export const SpecialInvoiceTypeEnum = Object.freeze({
  CONTRACT_MOD: '1_SC_C',
  FIELD_SERVICE: '1_FS_C',
  HOME_IMPROVEMENT: '1_HI_C',
  PREPAY: 'UPFRONT',
  SYSTEM_PURCHASE: '1_SP_C',
});

// user facing label to replace 'Full account balance'
export const SpecialInvoiceTypeUILabel = {
  [SpecialInvoiceTypeEnum.CONTRACT_MOD]: 'Contract Modification',
  [SpecialInvoiceTypeEnum.FIELD_SERVICE]: 'Field Service Invoice',
  [SpecialInvoiceTypeEnum.HOME_IMPROVEMENT]: 'Home Improvement Invoice',
  [SpecialInvoiceTypeEnum.PREPAY]: 'Prepay Invoice',
  [SpecialInvoiceTypeEnum.SYSTEM_PURCHASE]: 'System Purchase Invoice',
};
