/* eslint-disable max-len */
import React, { useMemo } from 'react';

import CollapsableCard from '../components/CollapsableCard';
import { css } from '@emotion/core';
import { Link, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import { useTargetZone } from '../hooks/useTargetZone';
import Skeleton from 'react-loading-skeleton';
import tableIcons from '../constants/tableIcons';

const infoLink = ''; // TODO

const TargetZone = () => {
  const { loading, error, data: allData } = useTargetZone();

  const { lastCalculatedData, data } = useMemo(() => {
    return {
      lastCalculatedData: allData.find((d) => typeof d.actualKwh === 'number' && d.actualKwh),
      data: allData,
    };
  }, [allData]);

  const messaging = lastCalculatedData ? (
    lastCalculatedData.status === 'Below target' ? (
      <>
        <Grid item xs style={{ paddingBottom: '1rem' }}>
          <Typography>
            <b>Messaging:</b> Your system’s energy production has been lower than its design expectation for an extended
            period. Please take safety precautions when ensuring the panels are clean and not obstructed by shade.
            Please contact support to schedule a service appointment.
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>Please contact support to schedule a service appointment.</Typography>
        </Grid>
      </>
    ) : lastCalculatedData.status === 'Above target' ? (
      <Grid item xs style={{ paddingBottom: '1rem' }}>
        <Typography>
          <b>Messaging:</b> Last month, your system’s energy production was above your target zone.
        </Typography>
      </Grid>
    ) : (
      <Grid item xs style={{ paddingBottom: '1rem' }}>
        <Typography>
          <b>Messaging:</b> Last month, your system’s energy production was within your target zone.
        </Typography>
      </Grid>
    )
  ) : null;

  return (
    <CollapsableCard
      title="App Target Zone: Customer-Facing"
      css={(theme) =>
        css`
          margin-bottom: ${theme.spacing(2)}px;
        `
      }
      initiallyExpanded={false}
      testId="target-zone-card"
    >
      {error ? (
        <Grid container direction="row" spacing={2} justifyContent="space-around">
          <Grid item xs={12} style={{ paddingRight: '2rem' }}>
            <Typography>Unknown error happened while fetching data</Typography>
          </Grid>
        </Grid>
      ) : loading ? (
        <Skeleton width={'100%'} height={400} />
      ) : allData.length === 0 ? (
        <Grid container direction="row" spacing={2} justifyContent="space-around">
          <Grid item xs={12} style={{ paddingRight: '2rem' }}>
            <Typography>No data available</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" spacing={2} justifyContent="space-around">
          <Grid item xs={6} style={{ paddingRight: '2rem' }}>
            <Grid container alignItems="flex-start" direction="column" spacing={1}>
              <Grid item xs>
                <Typography>
                  This green band shows the expected normal range of production for your system. Because this is a
                  general trend showing the seasonality of solar production, it only appears on the year view.
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography>
                  <b>Location in app:</b> Log in and tap <b>Charts</b> from the menu, then <b> Solar energy produced</b>{' '}
                  and <b>Year</b>.
                </Typography>
              </Grid>
              <Grid item xs style={{ paddingTop: '1rem' }}>
                <Typography variant="h5">Last Month</Typography>
              </Grid>
              <Grid item xs>
                <Typography>
                  <b>Date:</b> {lastCalculatedData ? `${lastCalculatedData.month}` : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs>
                    <Typography>
                      <b>Status:</b> {lastCalculatedData ? `${lastCalculatedData.status}` : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Typography>
                      <b>Actual Production:</b> {lastCalculatedData ? `${lastCalculatedData.actualKwh} kWh` : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {messaging}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography variant="h5">Full History</Typography>
              {infoLink ? (
                <Link
                  href={infoLink}
                  target="_blank"
                  css={css`
                    color: #0073e0;
                    font-size: 16px;
                    font-weight: 500;
                    text-decoration: none;
                    margin-left: 15px;
                    margin-top: 2px;
                  `}
                >
                  View Knowledge Article
                </Link>
              ) : null}
            </div>
            <MaterialTable
              title={null}
              columns={[
                {
                  title: 'Month',
                  field: 'month',
                  width: 1,
                  render: (dt) => dt.month,
                  headerStyle: {
                    paddingLeft: 0, // Remove horizontal padding from the header
                    paddingRight: 0,
                  },
                  cellStyle: {
                    paddingLeft: 0, // Remove horizontal padding from the cells
                    paddingRight: 0,
                  },
                },
                { title: 'Actual kWh', field: 'actualKwh', width: 1 },
                {
                  title: 'Status',
                  field: 'status',
                  width: 1,
                  cellStyle: { textTransform: 'capitalize' }, // Capitalize text using CSS
                },
              ]}
              data={data}
              localization={{ body: { emptyDataSourceMessage: 'No data' } }}
              options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 25],
                emptyRowsWhenPaging: false,
                paging: data.length > 5,
                draggable: false,
                search: false,
                showTitle: false,
                toolbar: false,
              }}
              icons={tableIcons}
              style={{
                backgroundColor: 'transparent', // Remove table's overall background
                boxShadow: 'none', // Remove table's shadow
              }}
            />
          </Grid>
        </Grid>
      )}
    </CollapsableCard>
  );
};

TargetZone.propTypes = {};

export default TargetZone;
