import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ResetPaymentWarningLabels } from './paymentConstants';

const ResetPaymentInfoWarningModal = ({ isOpen, onClose, onResetForm }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" disableEscapeKeyDown>
      <DialogTitle disableTypography>
        <Typography variant="h6">{ResetPaymentWarningLabels.RESET_PAYMENT_WARNING_TITLE}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          css={css`
            position: absolute;
            right: 8px;
            top: 8px;
            color: #9e9e9e;
          `}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        css={css`
          min-width: 336px;
          padding: 16px;
        `}
        dividers
      >
        <DialogContentText color="textPrimary">
          {ResetPaymentWarningLabels.RESET_PAYMENT_WARNING_TEXT}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        css={css`
          margin: 0;
          padding: 8px;
        `}
      >
        <Button autoFocus onClick={onClose} color="primary">
          {ResetPaymentWarningLabels.CANCEL}
        </Button>
        <Button autoFocus onClick={onResetForm} color="primary">
          {ResetPaymentWarningLabels.CHANGE_ANYWAY}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResetPaymentInfoWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onResetForm: PropTypes.func.isRequired,
};

export default ResetPaymentInfoWarningModal;
