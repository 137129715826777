import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useHybridAuthToken } from '../../components/hybridAuthToken';
import { MessageType, PayMethodEnum, PaymentFormLabels, UIPaymentMethodLongEnum } from './paymentConstants';
import LinearProgress from '@material-ui/core/LinearProgress';
import PaymentMethodOptions from './PaymentMethodOptions';
import ReadOnlyFields from './ReadOnlyFields';
import ExperiencePaymentsUI from './ExperiencePaymentsUI';
import { Button } from '@sunrun/component-library';
import { AutopayConfirmationModal } from './AutopayConfirmationModal';
import ResetPaymentInfoWarningModal from './ResetPaymentInfoWarningModal';

const AutopayEnrollment = ({ userInfo }) => {
  const hybridAuth = useHybridAuthToken();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PayMethodEnum.CHECKING);
  const [showPaymentForm, setShowPaymentForm] = useState(false); // locking values / reset intitializing form
  const allowCreditCardAutoPay = userInfo.autopayByCreditCardEligible;
  const [isResetPaymentInfoWarningModalOpen, setIsResetPaymentInfoWarningModalOpen] = useState(false);
  const [successResults, setSuccessResults] = useState();

  const onSuccess = (successPayload) => {
    setSuccessResults(successPayload);
    setShowPaymentForm(false);
  };

  const initializeFormProps = useMemo(() => {
    if (!userInfo) {
      return;
    }

    const payload = {
      amount: 0,
      authToken: hybridAuth,
      payMethod: selectedPaymentMethod,
      showCancelPaymentButton: false,
      repAssisted: true,
      userInfo,
    };

    return {
      payload,
      type: MessageType.INBOUND_INITIALIZE_AUTOPAY,
      path: '/autopay',
    };
  }, [selectedPaymentMethod, userInfo, hybridAuth]);

  const handlePaymentMethodOnChange = (value) => {
    setSelectedPaymentMethod(value);
  };

  const handleContinueOnPress = () => {
    setShowPaymentForm(true);
  };

  const PreInitializedEditableFields = () => {
    return (
      <div data-testid="pre-initialize-editable-fields">
        <PaymentMethodOptions
          selectedPaymentMethod={selectedPaymentMethod}
          handlePaymentMethodOnChange={handlePaymentMethodOnChange}
          isCardEligible={allowCreditCardAutoPay}
        />
        <Button
          primary={true}
          data-testid="proceed-to-enter-payment-details"
          disabled={showPaymentForm}
          onClick={handleContinueOnPress}
        >
          {PaymentFormLabels.ENTER_PAYMENT_DETAILS}
        </Button>
        <ResetPaymentInfoWarningModal
          isOpen={isResetPaymentInfoWarningModalOpen}
          onClose={() => setIsResetPaymentInfoWarningModalOpen(false)}
          resetFormOnEdit={() => {
            setShowPaymentForm(false);
            setIsResetPaymentInfoWarningModalOpen(false);
          }}
        />
      </div>
    );
  };

  const PostInitializedReadOnlyFields = () => {
    return (
      <ReadOnlyFields
        selectedPaymentMethod={selectedPaymentMethod}
        onEditRequest={() => setIsResetPaymentInfoWarningModalOpen(true)}
      />
    );
  };

  if (!hybridAuth) return <LinearProgress />;

  return (
    <div data-testid="autopay-enrollment-container">
      {successResults && (
        <AutopayConfirmationModal
          lastFour={successResults.lastFour}
          paymentMethod={UIPaymentMethodLongEnum[selectedPaymentMethod]}
          email={userInfo.email}
        />
      )}
      {!showPaymentForm && <PreInitializedEditableFields />}
      {!successResults && showPaymentForm && (
        <>
          <PostInitializedReadOnlyFields />
          <ExperiencePaymentsUI initializeFormProps={initializeFormProps} onSuccess={onSuccess} />
        </>
      )}
      <ResetPaymentInfoWarningModal
        isOpen={isResetPaymentInfoWarningModalOpen}
        onClose={() => setIsResetPaymentInfoWarningModalOpen(false)}
        onResetForm={() => {
          setShowPaymentForm(false);
          setIsResetPaymentInfoWarningModalOpen(false);
        }}
      />
    </div>
  );
};

AutopayEnrollment.propTypes = {
  userInfo: PropTypes.shape({
    prospectId: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    mobilePhoneNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    lastDueDate: PropTypes.string,
    totalAmountDue: PropTypes.string,
    autopayByCreditCardEligible: PropTypes.bool,
  }),
};

export default AutopayEnrollment;
